import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ImageCropperModule } from "ngx-image-cropper";
import { VisualCropper } from "./component";

@NgModule({
	declarations: [
		VisualCropper
	],

	exports: [
		VisualCropper
	],
	imports: [
		CommonModule, ImageCropperModule
	]
})

export class VisualCropperModule {
}
