import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { ImageUpload } from "./component";

@NgModule({
	declarations: [
		ImageUpload
	],

	exports: [
		ImageUpload
	],

	imports: [
		CommonModule, MatIconModule, MatProgressSpinnerModule
	]
})

export class ImageUploadModule {
}
