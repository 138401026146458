import { ComponentRef } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { SnackbarCreateAction, SnackbarCreateData, SnackbarLevel } from "@tsng/common/snackbar";
import { Action, EventBus } from "@tsng/core";
import { DialogContentComponent, Modal, ModalRoute } from "@twly/common";
import { takeUntil } from "rxjs/operators";
import { ContactFormComponent } from "../../component/contact/component";

export function setupComponentHooks(
	dialogRef: MatDialogRef<DialogContentComponent<ContactFormComponent>>,
	componentRef: ComponentRef<ContactFormComponent>,
	eventBus: EventBus
) {
	componentRef.instance.ticketCreated.pipe(takeUntil(dialogRef.afterClosed())).subscribe(() => {
		dialogRef.close();
		const action: SnackbarCreateAction = new Action<SnackbarCreateData>("snackbar/create", {
			message: $localize`:Contact form send:Your form has been send to us, we will reply as soon as possible`,
			level: SnackbarLevel.SUCCESS
		});
		eventBus.send(action.type, action);
	});
}

export function translatedTitle() {
	return $localize`:The title of the support modal:Contact us`;
}

export function componentLoader() {
	return import("../../component/contact/component").then(m => m.ContactFormComponent);
}

export const supportFormModalRoute: ModalRoute<ContactFormComponent> = {
	path: "support",
	outlet: "modal",
	component: Modal,
	data: {
		title: translatedTitle,
		componentLoader: componentLoader,
		setupComponentHooks
	}
};
