import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { TsNgFormModule } from "@tsng/form";
import { UrlButtonComponent } from "./component";

@NgModule({
	declarations: [UrlButtonComponent],
	exports: [UrlButtonComponent],
	imports: [CommonModule, MatIconModule, TsNgFormModule, ReactiveFormsModule, MatFormFieldModule]
})

export class UrlButtonComponentModule {
}
