import { NgModule } from "@angular/core";
import { IsoTimePipe } from "./pipe";

@NgModule({
	exports: [
		IsoTimePipe
	],
	declarations: [
		IsoTimePipe
	]

})
export class IsoTimeModule {
}