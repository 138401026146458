import { Injectable } from '@angular/core';
import { BaseForm } from "@twly/common";
import { Subject } from 'rxjs';

export type FormMap =  Map<string, BaseForm>;

export class FormCondition {

	forms = new Map<string, BaseForm>()

	public get isEmpty() {
		return this.forms.size === 0;
	}

	public get isNotEmpty() {
		return this.forms.size > 0;
	}

	public get exists() {
		return this.forms.size > 0;
	}

	public get notExists() {
		return this.forms.size === 0;
	}

	add(form: BaseForm) {
		this.forms[form.name] = form
	}
}

@Injectable({providedIn: "root"})
export class FormComponentService {

	forms: BaseForm[] = [];

	/**
	 * Returns true if any of the forms is dirty, false otherwise
	 */
	public get dirty(): FormCondition {
		return this.addIf( form => {
			return form.form.dirty;
		});
	}

	close(force: boolean): boolean {
		this.forms.forEach(f => f.close(force));
		return true;
	}

	register(form: BaseForm) {
		this.forms[form.name] = form;
	}

	private addIf( fn: (BaseForm) => boolean): FormCondition {
		const fc = new FormCondition();
		this.forms.forEach(form => {if(fn(form) === true) fc.add(form) });
		return fc;
	}
}
