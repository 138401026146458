import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { PDetailCardComponent } from "./component";

@NgModule({
	declarations: [
		PDetailCardComponent
	],

	exports: [
		PDetailCardComponent
	],

	imports: [
		CommonModule, MatIconModule, ReactiveFormsModule
	]
})

export class PDetailCardModule {
}
