import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { TsNgFormModule } from "@tsng/form";
import { UrlButtonComponentModule } from "../url-button/module";
import { LatLonSubformComponent } from "./component";

@NgModule({
	declarations: [LatLonSubformComponent],
	exports: [LatLonSubformComponent],
	imports: [
		CommonModule,
		MatIconModule,
		TsNgFormModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		UrlButtonComponentModule
	]
})

export class LocationComponentModule {
}
