import { NgModule } from "@angular/core";
import { PrefillFieldsPipe } from "./prefill-fields-pipe";

@NgModule({
	declarations: [PrefillFieldsPipe],

	exports: [
		PrefillFieldsPipe
	],

	imports: [

	]
})

export class UtilModule {
}
