import { SCHEMA_DEFINITION, SchemaDefinition } from "@tsng/store";
import { additionalSchemaDefinitions } from "@twly/core";
import * as schemaDefinition from "../../../assets/schema.json";


export function schemaDefinitionFactory(): SchemaDefinition {
	const definition: SchemaDefinition = (schemaDefinition as any).default;
	definition.tables = Object.assign({},
		definition.tables,
		additionalSchemaDefinitions
	);
	definition.tables.user.relations["_fk_user_chatmsg"] ={
			"column": "id",
			"references": "chatmsg.userId"
		};
	return definition;
}

export const SCHEMA_DEFINITION_PROVIDER = {
	provide: SCHEMA_DEFINITION,
	useFactory: schemaDefinitionFactory
};
