import { Component, Injector, Type } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { ImageSetStatusHandler } from "@twly/core";
import { loginRouteAnimation } from "./animations/login.animation";

@Component({
	selector: "app-root",
	template: `
		<router-outlet name="modal"></router-outlet>
		<div class="content" [@routeAnimations]="prepareRoute(outlet)">
			<router-outlet #outlet="outlet"></router-outlet>
		</div>`,
	styleUrls: ["./component.scss"],
	animations: [loginRouteAnimation]
})
export class AppComponent {
	title = "ROOT";
	servicesToBootstrap: Type<unknown>[] = [
		ImageSetStatusHandler
	];


	constructor(private injector: Injector) {
		this.servicesToBootstrap.forEach(service => this.injector.get(service));
	}

	prepareRoute(outlet: RouterOutlet) {
		return outlet && outlet.activatedRouteData && outlet.activatedRouteData["animation"];
	}
}
