import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { RouterModule } from "@angular/router";
import { TsNgFormModule } from "@tsng/form";
import { ImageUploadModule } from "../../image-upload/module";
import { ImageSetCreateModal } from "./component";

@NgModule({
	declarations: [
		ImageSetCreateModal
	],

	exports: [
		ImageSetCreateModal
	],

	imports: [
		CommonModule, ImageUploadModule, TsNgFormModule, ReactiveFormsModule, RouterModule, MatIconModule, MatDialogModule
	]
})

export class ImageSetCreateModule {
}
