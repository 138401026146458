import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { NavigateBackModule } from "../navigate-back/module";
import { FormCloseButton } from "./component";

@NgModule({
	declarations: [FormCloseButton],

	exports: [
		FormCloseButton
	],

	imports: [
		CommonModule, MatIconModule, NavigateBackModule
	]
})

export class FormCloseButtonModule {
}
