<div class="detail-card">

	<h2>{{ title }}-{{modelId}}</h2>

	<div class="form-actions">
		<button navigateBack type="button">
			<mat-icon>close</mat-icon>
		</button>
		<button (click)="save()" type="submit">
			<span i18n="Save button@@SAVE_BUTTON">Save</span>
			<mat-icon>save</mat-icon>
		</button>
	</div>

	<div class="divider"></div>

	<div id="content">
		<ng-content></ng-content>
		<!--	<div class="form-actions">-->
		<!--		<button i18n="Cancel button@@CANCEL_BUTTON" type="button" navigateBack>-->
		<!--			<mat-icon>close</mat-icon>-->
		<!--		</button>-->
		<!--		<button i18n="Save button@@SAVE_BUTTON" type="submit" >-->
		<!--&lt;!&ndash;			(click)="f.onSubmit($event)"&ndash;&gt;-->
		<!--			Save-->
		<!--			<mat-icon>save</mat-icon>-->
		<!--		</button>-->
		<!--	</div>-->

		<!--	<div class="form-actions">-->
		<!--		<button i18n="Cancel button@@CANCEL_BUTTON" type="button" navigateBack>-->
		<!--			<mat-icon>close</mat-icon>-->
		<!--		</button>-->
		<!--		<button i18n="Save button@@SAVE_BUTTON" type="submit" >-->
		<!--			&lt;!&ndash;			(click)="f.onSubmit($event)"&ndash;&gt;-->
		<!--			Save-->
		<!--			<mat-icon>save</mat-icon>-->
		<!--		</button>-->
		<!--	</div>-->
	</div>
</div>
