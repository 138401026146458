import { ComponentRef } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { TsNgChangePasswordForm } from "@tsng/account";
import { SnackbarCreateAction, SnackbarCreateData, SnackbarLevel } from "@tsng/common/snackbar";
import { Action, EventBus } from "@tsng/core";
import { DialogContentComponent, Modal, ModalRoute } from "@twly/common";
import { takeUntil } from "rxjs/operators";

export function setupComponentHooks(
	dialogRef: MatDialogRef<DialogContentComponent<TsNgChangePasswordForm>>,
	componentRef: ComponentRef<TsNgChangePasswordForm>,
	eventBus: EventBus
) {
	componentRef.instance.changed.pipe(takeUntil(dialogRef.afterClosed())).subscribe(() => {
		dialogRef.close();
		const action: SnackbarCreateAction = new Action<SnackbarCreateData>("snackbar/create", {
			message: $localize`:Password changed successfully@@TsNgChangePasswordForm:The password has been changed.`,
			level: SnackbarLevel.SUCCESS
		});
		eventBus.send(action.type, action);
	});
}

export function translatedTitle() {
	return $localize`:The title of the change password form@@TsNgChangePasswordForm:Change your password`;
}

export function componentLoader() {
	return import("@tsng/account").then(m => m.TsNgChangePasswordForm);
}

export const changePasswordFormModalRoute: ModalRoute<TsNgChangePasswordForm> = {
	path: "account/change-password",
	outlet: "modal",
	component: Modal,
	data: {
		title: translatedTitle,
		componentLoader: componentLoader,
		setupComponentHooks
	}
};
