import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from "@angular/router";

@Injectable()
export class DetailPageDeactivationGuard implements CanDeactivate<any> {

	canDeactivate(component: any,
		currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot
	): boolean {
		const form = component.formComponent?.form || component.form;

		// Compatibility
		if (form.dirty === false) {
			return true;
		}

		if (window.confirm($localize`:Unsaved changes warning|The warning that there are unsaved changesd@@DetailPageDeactivationGuard:You have unsaved changes, are you sure you want to leave?`)) {
			form.reset();
			return true;
		}
		return false;
	}
}
