import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ModalRouteData } from "../route-config";

@Component({
	selector: "app-dialog-component",
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: "./component.html",
	styleUrls: ["./component.scss"]
})
export class DialogComponent<T> {
	constructor(@Inject(MAT_DIALOG_DATA) public data: ModalRouteData<T>) {
	}

	get title() {
		if (this.data.title === null) {
			return "";
		}
		if (typeof this.data.title === "string") {
			return this.data.title;
		}
		return this.data.title();
	}
}
