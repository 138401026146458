import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { FormActionButton } from "./component";

@NgModule({
	declarations: [FormActionButton],

	exports: [
		FormActionButton
	],

	imports: [
		CommonModule, MatIconModule
	]
})

export class FormActionButtonModule {
}
