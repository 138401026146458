import { Component, forwardRef, OnInit, ViewChild } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { Account } from "@tsng/account";
import { TsNgSideNavLayoutComponent } from "@tsng/common/sidenav";
import { SnackbarCreateAction, SnackbarCreateData, SnackbarLevel } from "@tsng/common/snackbar";
import { Action, EventBus } from "@tsng/core";
import { Logger, LoggerLocator } from "@tsng/logging";
import { SelectorProvider } from "@tsng/store";
import { ConfigService, TwentelyAccount } from "@twly/core";
import { Map } from "immutable";
import { Observable, of, OperatorFunction } from "rxjs";
import { map, publishReplay, refCount } from "rxjs/operators";
import { routeAnimation } from "../../../animations/route.animation";
import { Layout, LAYOUT_COMPONENT } from "../layout";

@Component({
	selector: "app-admin-layout",
	templateUrl: "./component.html",
	styleUrls: ["./component.scss", "../sidebar.scss"],
	providers: [
		{
			provide: LAYOUT_COMPONENT,
			useExisting: forwardRef(() => AdminLayoutComponent)
		}
	],
	animations: [
		routeAnimation
	]
})
export class AdminLayoutComponent implements Layout, OnInit {
	accountObservable: Observable<TwentelyAccount>;
	private logger: Logger = LoggerLocator.getLogger("AdminLayoutComponent")();
	@ViewChild("sidenav", {static: true}) private sideNav: TsNgSideNavLayoutComponent;
	private readonly accountSelector: () => OperatorFunction<any, any>;

	constructor(
		private eventBus: EventBus,
		private selectorProvider: SelectorProvider,
		public configService: ConfigService
	) {
		this.accountSelector = this.selectorProvider.getBuilder().main("account").build();
	}

	ngOnInit(): void {
		this.accountObservable = of({}).pipe(this.accountSelector(),
			map((accountMap: Map<number, { account: Account }>) => (accountMap.first().account as any) as TwentelyAccount),
			publishReplay(1),
			refCount()
		);

	}

	closeSideNav(): void {
		this.sideNav.close();
	}

	openSideNav(): void {
		this.sideNav.open();
	}

	sideNavStateChange(): Observable<boolean> {
		return this.sideNav.openedChange();
	}

	prepareRoute(outlet: RouterOutlet) {
		return outlet && outlet.activatedRouteData && outlet.activatedRouteData["animation"];
	}

	doLogout() {
		const action = new Action("internal/logout", -1, -1, {});
		this.eventBus.request("internal/logout", action).subscribe(() => {
			location.reload();

		}, error => {

			const action: SnackbarCreateAction = new Action<SnackbarCreateData>("snackbar/create", {
				message: $localize`:Logout failed@@AdminLayoutComponent.logoutFailedMessage:We were unable to log you out properly, please try again or refresh the page`,
				level: SnackbarLevel.ERROR
			});
			this.eventBus.send(action.type, action);
			this.logger.error("Logout failed", {error});
		});
	}

	resetPageState(selector: string) {
		const action = new Action("state/clear", selector as any, -1, {});
		this.eventBus.send(action.type, action);
	}
}
