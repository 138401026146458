import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";

export interface Layout {
	openSideNav(): void;

	closeSideNav(): void;

	sideNavStateChange(): Observable<boolean>;
}

export const LAYOUT_COMPONENT = new InjectionToken<Layout>("LayoutComponent");
