import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { FormDeleteButton } from "./component";

@NgModule({
	declarations: [FormDeleteButton],

	exports: [
		FormDeleteButton
	],

	imports: [
		CommonModule, MatIconModule
	]
})

export class FormDeleteButtonModule {
}
