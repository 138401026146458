import {
	ChangeDetectorRef, ComponentFactoryResolver, ComponentRef, Directive, Inject, OnDestroy, OnInit, ViewContainerRef
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { EventBus } from "@tsng/core";
import { from, ReplaySubject } from "rxjs";
import { ModalRouteData } from "../route-config";

@Directive({
	selector: "[mat-dialog-content][app-dialog-content-component]"
})
export class DialogContentComponent<T> implements OnInit, OnDestroy {
	private destroyed: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

	constructor(
		public dialogRef: MatDialogRef<DialogContentComponent<T>>,
		@Inject(MAT_DIALOG_DATA) private data: ModalRouteData<T>,
		private componentFactoryResolver: ComponentFactoryResolver,
		private viewContainerRef: ViewContainerRef,
		private changeDetectorRef: ChangeDetectorRef,
		private eventBus: EventBus
	) {
	}

	ngOnInit() {
		from(this.renderComponent(this.data)).subscribe(componentRef => {
			this.data.setupComponentHooks(this.dialogRef, componentRef, this.eventBus);
			this.changeDetectorRef.markForCheck();
			this.changeDetectorRef.detectChanges();
		});
	}

	ngOnDestroy(): void {
		this.destroyed.next(true);
		this.destroyed.complete();
		this.destroyed = null;
	}

	private async renderComponent<T>(data: ModalRouteData<T>): Promise<ComponentRef<T>> {
		const {componentLoader} = data;
		const loadedComponentClass = await componentLoader();
		const componentFactory = this.componentFactoryResolver.resolveComponentFactory(loadedComponentClass);
		const componentRef = this.viewContainerRef.createComponent(componentFactory);
		componentRef.changeDetectorRef.markForCheck();
		componentRef.changeDetectorRef.detectChanges();
		return componentRef;
	}
}
