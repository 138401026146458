import { EqualOperator, Source } from "@tsng/store";

/**
 * Set all keys in the object (interpreted as a map<key, value>) as an equal operator in the given source
 *
 * Example:
 * the call addEqualOperator(source, {
 * 	siteId: {
 * 		required: true,
 * 		value: 14
 * 	},
 * 	otherField: 16
 * })
 *
 * executes:
 *
 * source.addOperator("siteId", new EqualOperator("siteId", 14));
 * source.addOperator("otherField", new EqualOperator("otherField", 14));
 *
 *
 * @param source
 * @param o
 */
export function addEqualOperator(source: Source, o: object) {
	for (const [k, v] of Object.entries(o)) {
		if(typeof v === 'object' && v['value'] != null) {
			source.addOperator(k, new EqualOperator(k, v['value']));
		} else {
			source.addOperator(k, new EqualOperator(k, v));
		}
	}
}

