import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { DetailCardComponent } from "./component";

@NgModule({
	declarations: [
		DetailCardComponent
	],

	exports: [
		DetailCardComponent
	],

	imports: [
		CommonModule, MatIconModule
	]
})

export class DetailCardModule {
}
