import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { RouterModule } from "@angular/router";
import { TsNgFormModule } from "@tsng/form";
import { ImageUploadModule } from "../../image-upload/module";
import { VisualCropperModule } from "../../visual-cropper/module";
import { ImageSetUpdateModal } from "./component";

@NgModule({
	declarations: [
		ImageSetUpdateModal
	],

	exports: [
		ImageSetUpdateModal
	],
	imports: [
		CommonModule,
		ImageUploadModule,
		TsNgFormModule,
		ReactiveFormsModule,
		RouterModule,
		MatIconModule,
		VisualCropperModule
	]
})

export class ImageSetUpdateModule {
}
