import { NgModule } from "@angular/core";
import { DetailPageDeactivationGuard } from "./guard";

@NgModule({
	providers: [
		DetailPageDeactivationGuard
	]
})
export class DetailPageDeactivationModule {

}