import { AfterViewInit, Component, ContentChild, Directive, Injector, Input, OnDestroy, OnInit } from "@angular/core";
import { SnackbarLevel } from "@tsng/common/snackbar";
import { Action, EventBus } from "@tsng/core";
import { defaultFormGroupToAction, TsAbstractControl } from "@tsng/form";
import { Logger, LoggerLocator } from "@tsng/logging";
import {
	CreateAction,
	CreatedAction,
	EqualOperator,
	SelectorMerger,
	Source,
	SourceBuilder,
	SourceProvider,
	UpdateAction,
	UpdatedAction
} from "@tsng/store";
import { OrderedMap } from "immutable";
import { Observable, ReplaySubject } from "rxjs";
import { filter, map, switchMap, takeUntil, tap } from "rxjs/operators";

@Directive()
export abstract class DetailForm implements OnInit, OnDestroy {
	abstract form: TsAbstractControl;
	abstract entity: string;
	protected logger: Logger = LoggerLocator.getLogger("DetailForm")();
	protected eventBus: EventBus;
	protected formToActionFactory: (AbstractControl, string) => object = defaultFormGroupToAction;
	protected destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
	protected modelIdObservable = new ReplaySubject<string | number>(1);
	protected sourceBuilder: SourceBuilder;
	protected idFieldName: string = "id";

	protected constructor(injector: Injector) {
		this.eventBus = injector.get(EventBus);
		this.sourceBuilder = injector.get(SourceProvider).getBuilder();
	}

	@Input("modelId") set modelId(id: string | number) {
		this.modelIdObservable.next(id);
	}

	@Input("idField") set filterField(idFieldName: string) {
		this.idFieldName = idFieldName;
	}

	ngOnInit() {
		this.loadData();

		this.form.valueChanges.subscribe(v => {
			this.logger.info(v);
		});
	}

	ngOnDestroy(): void {
		this.destroyed.next(true);
		this.destroyed.complete();
		this.destroyed = null;
	}

	save() {
		if (this.canFormBeSaved() === false) return;
		this.sendAction(this.formToActionFactory(this.form, this.entity));
	}

	onSaveSuccess(action: object) {
		this.showSnack({
			message: $localize`:Saved@@DetailCardComponent.saveSuccessMessage:The information has been saved`,
			level: SnackbarLevel.SUCCESS
		});
	}

	onSaveError(error) {
		this.showSnack({
			message: $localize`:Save failed@@DetailCardComponent.saveErrorMessage:We were unable to save the information, please try again`,
			level: SnackbarLevel.ERROR
		});
	}

	onSaveFormIsInvalid() {
		this.logger.warning("Form is not valid and therefore cannot be saved");
		this.showSnack({
			message: $localize`:Form invalid@@DetailCardComponent.formInvalidMessage:Please ensure all fields on the form have been filled out correctly`,
			level: "warning"
		});
	}

	onSaveNoChanges() {
		this.logger.warning("There are no changes therefore the form cannot be saved");
		this.showSnack({
			message: $localize`:Form was not changed@@DetailCardComponent.noChangesMessage:There are no changes to save`,
			level: "warning"
		});
	}

	protected getInitialSourceBuilder() {
		return this.sourceBuilder.setMergeFactory(SelectorMerger.createdNestedMerger);
	}

	protected setSourceEntities(sourceBuilder: SourceBuilder): SourceBuilder {
		return sourceBuilder.main(this.entity);
	}

	protected loadData() {
		this.modelIdObservable.pipe(
			takeUntil(this.destroyed),
			tap(id => {
				this.logger.info("Received id: " + id + " for field " + this.idFieldName);
				if (id == null) {
					// TODO: Ask save existing changes
					this.resetForm();
				}
			}),
			filter(id => (id != null) && ((typeof id == "string") || (typeof id == "number" && id > 0))),
			map(id => {
				const initialBuilder = this.getInitialSourceBuilder();
				const source = this.setSourceEntities(initialBuilder).build();
				return this.setIdFilter(source, id);
			}),
			switchMap(source => source.dataChanged() as Observable<OrderedMap<unknown, unknown>>),
			map(dataMap => dataMap.first()),
			tap(id => {
				if (id == null) {
					// Not found
					this.resetForm();
				}
			}),
			filter(value => value != null)
		).subscribe(value => {
			this.patchForm(value);
		});
	}

	protected resetForm() {
		this.form.reset();
	}

	protected patchForm(data: unknown) {
		this.form.patch(data);
	}

	protected resetFormState() {
		// this.form.reset();
		this.form.markAsPristine();
		this.form.markAsUntouched();
	}

	protected canFormBeSaved(): boolean {
		if (this.form.valid === false) {
			this.onSaveFormIsInvalid();
			return false;
		}
		if (this.form.getChanges() == null) {
			this.onSaveNoChanges();
			return false;
		}
		return true;
	}

	protected sendAction(action) {
		this.eventBus.request<CreateAction | UpdateAction, CreatedAction | UpdatedAction>(action.type.includes("update")
			? "entity/update" : "entity/create", action)
			.subscribe(value => {
				this.resetFormState();
				this.onSaveSuccess(value.body);
			}, error => {
				this.onSaveError(error);
			});
	}

	protected setIdFilter(source: Source, id: string | number) {
		return source.addOperator("idFilter", new EqualOperator(this.idFieldName, id));
	}

	protected showSnack(snack: any) {
		const action = new Action("snackbar/create", {
			message: snack.message,
			level: snack.level
		});
		this.eventBus.send(action.type, action);
	}
}

@Component({
	selector: "detail-card-component",
	templateUrl: "./component.html",
	styleUrls: ["./component.scss"]
})
export class DetailCardComponent implements AfterViewInit {

	@Input("title") title: string;
	@Input("modelId") modelId: string | number;

	@ContentChild(DetailForm) detailForm: DetailForm;

	save() {
		this.detailForm.save();
	}

	ngAfterViewInit() {
	}



}
