import { Injectable } from "@angular/core";
import { Action, EventBus, Message } from "@tsng/core";
import { LoggerLocator } from "@tsng/logging";
import { tap } from "rxjs/operators";

export type ImageSetStatusUpdateAction = Action<{
	[entityProperty: string]: any
}>

export type ImageSetStatusUpdatedAction = Action<{
	[entityProperty: string]: any
}>

@Injectable({
	providedIn: "root"
})
export class ImageSetStatusHandler {
	address = "imageSet/update-state";
	private logger = LoggerLocator.getLogger("ImageSetStatusHandler")();

	constructor(private eventBus: EventBus) {
		this.listenToMessages();
	}

	private listenToMessages() {
		this.eventBus.localConsumer<ImageSetStatusUpdateAction>(this.address)
			.subscribe(message => {
				this.handleLocalUpdate(message);
			}, error => {
				this.logger.fatal(error);
			});
	}

	private handleLocalUpdate(message: Message<ImageSetStatusUpdateAction>) {
		this.eventBus.request<ImageSetStatusUpdateAction, ImageSetStatusUpdatedAction>(message.body.type, message.body)
			.pipe(tap(result => {
				const statusUpdateAction = result.body;
				const updatedAction = new Action(
					"imageSet/updated",
					statusUpdateAction.id,
					statusUpdateAction.rev,
					statusUpdateAction.data
				);
				this.eventBus.send(updatedAction.type, updatedAction);
			}))
			.subscribe(result => {
				message.reply(result.body);
			}, error => {
				message.fail(error.failureCode, error.message);
			});
	}
}
