import { ComponentRef } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Action, EventBus } from "@tsng/core";
import { DialogContentComponent, Modal, ModalRoute } from "@twly/common";
import { takeUntil } from "rxjs/operators";
import { ImageSetUpdateModal } from "./component";

export function setupComponentHooks(
	dialogRef: MatDialogRef<DialogContentComponent<ImageSetUpdateModal>>,
	componentRef: ComponentRef<ImageSetUpdateModal>,
	eventBus: EventBus
) {
	componentRef.instance.closed.pipe(takeUntil(dialogRef.afterClosed())).subscribe(() => {
		eventBus.send("ui/images-set-modal/closed", new Action("ui/images-set-modal/closed", {}));
		dialogRef.close();
	});
}

export function translatedTitle() {
	return $localize`:The title of the image set update form@@ImageSetUpdateModal.title:Crop the image`;
}

export function componentLoader() {
	return import("./component").then(m => m.ImageSetUpdateModal);
}

export const imageSetUpdateModalRoute: ModalRoute<ImageSetUpdateModal> = {
	path: "image-set/update",
	outlet: "modal",
	component: Modal,
	data: {
		title: translatedTitle,
		componentLoader: componentLoader,
		setupComponentHooks
	}
};
