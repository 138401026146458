import { Component, forwardRef, ViewChild } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { TsNgSideNavLayoutComponent } from "@tsng/common/sidenav";
import { Observable } from "rxjs";
import { routeAnimation } from "../../../animations/route.animation";
import { Layout, LAYOUT_COMPONENT } from "../layout";

@Component({
	selector: "app-basic-layout",
	templateUrl: "./component.html",
	styleUrls: ["./component.scss", "../sidebar.scss"],
	providers: [
		{
			provide: LAYOUT_COMPONENT,
			useExisting: forwardRef(() => BasicLayoutComponent)
		}
	],
	animations: [
		routeAnimation
	]
})
export class BasicLayoutComponent implements Layout {
	@ViewChild("sidenav", {static: true}) private sideNav: TsNgSideNavLayoutComponent;

	closeSideNav(): void {
		this.sideNav.close();
	}

	openSideNav(): void {
		this.sideNav.open();
	}

	sideNavStateChange(): Observable<boolean> {
		return this.sideNav.openedChange();
	}

	prepareRoute(outlet: RouterOutlet) {
		return outlet && outlet.activatedRouteData && outlet.activatedRouteData["animation"];
	}
}
