import { NgModule } from "@angular/core";
import { DayOfWeekPipe } from "./pipe";

@NgModule({
	exports: [
		DayOfWeekPipe
	],
	declarations: [
		DayOfWeekPipe
	]

})
export class DayOfWeekModule {
}