import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { TsNgAccountModule } from "@tsng/account";
import { TsNgCommonSideNavModule } from "@tsng/common/sidenav";
import { TsNgCommonSnackbarModule } from "@tsng/common/snackbar";
import { TsNgStateModule } from "@tsng/common/state";
import { TsNgCoreModule } from "@tsng/core";
import { TsNgFormModule } from "@tsng/form";
import { TsNgStoreModule } from "@tsng/store";
import {
	ActivityCoreModule,
	AuthRoleCoreModule,
	CityCoreModule,
	CompanyCoreModule,
	CrawlerAddressCoreModule,
	CrawlerAddressLookupCoreModule,
	CrawlerDomainCoreModule,
	CrawlerPathStatusCoreModule,
	CrawlerUrlCoreModule,
	CrawlerUrlLinkCoreModule,
	DetailPageDeactivationModule,
	ImageCoreModule,
	ImageSetCoreModule,
	KeywordCoreModule,
	PropertyCoreModule,
	RENDERED_BY, RenderEnvironment,
	SharedBaseModule,
	SiteActivityCoreModule,
	SiteCoreModule,
	TicketCoreModule,
	TileSectionCoreModule,
	UserCoreModule,
	UserGroupCoreModule,
	UserGroupMemberCoreModule,
	VenueCoreModule,
	UserNotificationSettingCoreModule
} from "@twly/core";
import { environment } from "../environments/environment";
import { AppComponent } from "./component";
import { ImageSetCreateModule } from "./component/image-set/create-modal/module";
import { ImageSetUpdateModule } from "./component/image-set/update-modal/module";
import { AdminLayoutComponent } from "./component/layout/admin/component";
import { BasicLayoutComponent } from "./component/layout/basic/component";
import { SCHEMA_DEFINITION_PROVIDER } from "./core/schema/schema";
import { routes } from "./route";

@NgModule({
	declarations: [
		AppComponent, BasicLayoutComponent, AdminLayoutComponent
	],
	imports: [
		BrowserModule,
		ReactiveFormsModule,
		HttpClientModule,
		TsNgCoreModule,
		TsNgStateModule,
		TsNgStoreModule,
		TsNgFormModule,
		TsNgCommonSideNavModule,
		TsNgCommonSnackbarModule,
		MatIconModule,
		ActivityCoreModule,
		AuthRoleCoreModule,
		CityCoreModule,
		CompanyCoreModule,
		CrawlerDomainCoreModule,
		CrawlerUrlCoreModule,
		CrawlerAddressLookupCoreModule,
		CrawlerAddressCoreModule,
		CrawlerPathStatusCoreModule,
		CrawlerUrlLinkCoreModule,
		KeywordCoreModule,
		PropertyCoreModule,
		ImageSetCoreModule,
		ImageCoreModule,
		SiteCoreModule,
		SiteActivityCoreModule,
		TicketCoreModule,
		TileSectionCoreModule,
		UserCoreModule,
		VenueCoreModule,
		UserGroupCoreModule,
		UserGroupMemberCoreModule,
		BrowserAnimationsModule,
		TsNgAccountModule.forRoot(),
		BrowserAnimationsModule,
		MatMenuModule,
		MatButtonModule,
		DetailPageDeactivationModule,
		ImageSetUpdateModule,
		ImageSetCreateModule,
		UserNotificationSettingCoreModule,
		StoreDevtoolsModule.instrument({
			maxAge: 25,
			logOnly: environment.production
		}),
		RouterModule.forRoot(routes, {
			relativeLinkResolution: "corrected"
		}),
		SharedBaseModule.forRoot(environment)
	],
	providers: [
		SCHEMA_DEFINITION_PROVIDER,
		{
			provide: RENDERED_BY,
			useValue: RenderEnvironment.BROWSER
		}
	],
	bootstrap: [AppComponent]
})
export class Module {
}
