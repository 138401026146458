import { Routes } from "@angular/router";
import { imageSetCreateModalRoute } from "./component/image-set/create-modal/image-set-create-route";
import { imageSetUpdateModalRoute } from "./component/image-set/update-modal/image-set-update-route";
import { AdminLayoutComponent } from "./component/layout/admin/component";
import { BasicLayoutComponent } from "./component/layout/basic/component";
import { TwlyAuthorizationGuard } from "./core/authorization-guard/guard";
import { changeEmailFormModalRoute } from "./route/account/change-email-route";
import { changePasswordFormModalRoute } from "./route/account/change-password-route";
import { supportFormModalRoute } from "./route/support/support-modal-route";

export const routes: Routes = [
	changePasswordFormModalRoute,
	changeEmailFormModalRoute,
	supportFormModalRoute,
	imageSetCreateModalRoute,
	imageSetUpdateModalRoute,
	{
		path: "login",
		component: BasicLayoutComponent,
		children: [
			{
				path: "",
				data: {animation: "LoginPage"},
				loadChildren: () => import("./route/login/module").then(mod => mod.LoginPageModule)
			}
		]
	},
	{
		path: "request-password-reset",
		component: BasicLayoutComponent,
		children: [
			{
				path: "",
				data: {animation: "RequestPasswordPage"},
				loadChildren: () => import("./route/request-password-reset/module").then(mod => mod.RequestPasswordResetModule)
			}
		]
	},
	{
		path: "support",
		component: BasicLayoutComponent,
		children: [
			{
				path: "",
				loadChildren: () => import("./route/support/module").then(mod => mod.SupportPageModule)
			}
		]
	},
	{
		path: "account",
		component: AdminLayoutComponent,
		data: {permission: "user/view"},
		canActivate: [TwlyAuthorizationGuard],
		loadChildren: () => import("./route/account/module").then(mod => mod.AccountPageModule)
	},
	{
		path: "activity",
		component: AdminLayoutComponent,
		data: {permission: "activity/view-all"},
		canActivate: [TwlyAuthorizationGuard],
		loadChildren: () => import("./route/activity/module").then(mod => mod.ActivityPageModule)
	},
	{
		path: "crawler-domain",
		component: AdminLayoutComponent,
		data: {permission: "crawler/view-all"},
		canActivate: [TwlyAuthorizationGuard],
		loadChildren: () => import("./route/crawler/domain/module").then(mod => mod.CrawlerDomainModule)
	},
	{
		path: "crawler-domain-path",
		component: AdminLayoutComponent,
		data: {permission: "crawler/view-all"},
		canActivate: [TwlyAuthorizationGuard],
		loadChildren: () => import("./route/crawler/path/module").then(mod => mod.CrawlerPathStatusPageModule)
	},
	{
		path: "crawler-url",
		component: AdminLayoutComponent,
		data: {permission: "crawler/view-all"},
		canActivate: [TwlyAuthorizationGuard],
		loadChildren: () => import("./route/crawler/url/module").then(mod => mod.CrawlerUrlPageModule)
	},
	{
		path: "crawler-url-link",
		component: AdminLayoutComponent,
		data: {permission: "crawler/view-all"},
		canActivate: [TwlyAuthorizationGuard],
		loadChildren: () => import("./route/crawler/url-link/module").then(mod => mod.CrawlerUrlLinkPageModule)
	},
	{
		path: "crawler-address-lookup",
		component: AdminLayoutComponent,
		canActivate: [TwlyAuthorizationGuard],
		loadChildren: () => import("./route/crawlerAddressLookup/module").then(mod => mod.CrawlerAddressLookupPageModule)
	},
	{
		path: "crawler-address",
		component: AdminLayoutComponent,
		data: {permission: "crawler/view-all"},
		canActivate: [TwlyAuthorizationGuard],
		loadChildren: () => import("./route/crawlerAddress/module").then(mod => mod.CrawlerAddressPageModule)
	},
	{
		path: "city",
		component: AdminLayoutComponent,
		data: {permission: "city/view-all"},
		canActivate: [TwlyAuthorizationGuard],
		loadChildren: () => import("./route/city/module").then(mod => mod.CityPageModule)
	},
	{
		path: "venue",
		component: AdminLayoutComponent,
		data: {permission: "venue/view-all"},
		canActivate: [TwlyAuthorizationGuard],
		loadChildren: () => import("./route/venue/module").then(mod => mod.VenuePageModule)
	},
	{
		path: "user",
		component: AdminLayoutComponent,
		data: {permission: "user/view"},
		canActivate: [TwlyAuthorizationGuard],
		loadChildren: () => import("./route/user/module").then(mod => mod.UserPageModule)
	},
	{
		path: "notification",
		component: AdminLayoutComponent,
		canActivate: [TwlyAuthorizationGuard],
		loadChildren: () => import("./route/notification/module").then(mod => mod.NotificationPageModule)
	},
	{
		path: "keyword",
		component: AdminLayoutComponent,
		data: {permission: "keyword/view-all"},
		canActivate: [TwlyAuthorizationGuard],
		loadChildren: () => import("./route/keyword/module").then(mod => mod.KeywordPageModule)
	},
	{
		path: "crawler-domain",
		component: AdminLayoutComponent,
		data: {permission: "crawler/view"},
		canActivate: [TwlyAuthorizationGuard],
		loadChildren: () => import("./route/crawler/domain/module").then(mod => mod.CrawlerDomainModule)
	},
	{
		path: "site",
		component: AdminLayoutComponent,
		data: {permission: "site/view"},
		canActivate: [TwlyAuthorizationGuard],
		loadChildren: () => import("./route/site/module").then(mod => mod.SiteModule)
	},
	{
		path: "site-activity",
		component: AdminLayoutComponent,
		data: {permission: "siteActivity/view"},
		canActivate: [TwlyAuthorizationGuard],
		loadChildren: () => import("./route/siteActivity/module").then(mod => mod.SiteActivityPageModule)
	},
	{
		path: "ticket",
		component: AdminLayoutComponent,
		data: {permission: "ticket/view"},
		canActivate: [TwlyAuthorizationGuard],
		loadChildren: () => import("./route/ticket/module").then(mod => mod.TicketPageModule)
	},
	{
		path: "tile-section",
		component: AdminLayoutComponent,
		data: {permission: "tileSection/view-all"},
		canActivate: [TwlyAuthorizationGuard],
		loadChildren: () => import("./route/tileSection/module").then(mod => mod.TileSectionPageModule)
	},
	{
		path: "",
		pathMatch: "full",
		component: AdminLayoutComponent,
		data: {permission: "dashboard/view"},
		canActivate: [TwlyAuthorizationGuard],
		loadChildren: () => import("./route/dashboard/module").then(mod => mod.DashboardPageModule)
	}
];
