import { ComponentRef } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { TsNgChangeEmailForm } from "@tsng/account";
import { SnackbarCreateAction, SnackbarCreateData, SnackbarLevel } from "@tsng/common/snackbar";
import { Action, EventBus } from "@tsng/core";
import { DialogContentComponent, Modal, ModalRoute } from "@twly/common";
import { takeUntil } from "rxjs/operators";

export function setupComponentHooks(
	dialogRef: MatDialogRef<DialogContentComponent<TsNgChangeEmailForm>>,
	componentRef: ComponentRef<TsNgChangeEmailForm>,
	eventBus: EventBus
) {
	componentRef.instance.changed.pipe(takeUntil(dialogRef.afterClosed())).subscribe(() => {
		dialogRef.close();
		const action: SnackbarCreateAction = new Action<SnackbarCreateData>("snackbar/create", {
			message: $localize`:Email changed successfully@@TsNgChangeEmailForm:The change email confirmation mail has been send, please verify your new email.`,
			level: SnackbarLevel.SUCCESS
		});
		eventBus.send(action.type, action);
	});
}

export function translatedTitle() {
	return $localize`:The title of the change email form@@TsNgChangeEmailForm:Change your email`;
}

export function componentLoader() {
	return import("@tsng/account").then(m => m.TsNgChangeEmailForm);
}

export const changeEmailFormModalRoute: ModalRoute<TsNgChangeEmailForm> = {
	path: "account/change-email",
	outlet: "modal",
	component: Modal,
	data: {
		title: translatedTitle,
		componentLoader: componentLoader,
		setupComponentHooks
	}
};
