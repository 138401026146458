import { animate, group, query, style, transition, trigger } from "@angular/animations";
import { slideInAnimation, slideOutAnimation } from "@twly/common";

export const loginRouteAnimation = trigger("routeAnimations", [

	transition("LoginPage => RequestPasswordPage", slideInAnimation),
	transition("RequestPasswordPage => LoginPage", slideOutAnimation),
	transition("LoginPage => RegisterPage", slideInAnimation),
	transition("RegisterPage => LoginPage", slideOutAnimation),

	transition("LoginPage => *", [
		group([
			query(":leave", animate("500ms ease", style({
				position: "fixed",
				height: "100%",
				transform: "translateY(100%)"
			})))
		])
	]),

	transition("* => LoginPage", [
		query(":enter", style({
			position: "fixed",
			height: "100%",
			transform: "translateY(200%)"
		})),

		group([
			query(":enter", animate("500ms ease", style({
				opacity: 1,
				transform: "translateY(0%)"
			})))
		])
	])
]);
