import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { FormPublishButton } from "./component";

@NgModule({
	declarations: [FormPublishButton],

	exports: [
		FormPublishButton
	],

	imports: [
		CommonModule, MatIconModule
	]
})

export class FormPublishButtonModule {
}
