import { transition, trigger } from "@angular/animations";
import { fadeAnimation, slideInAnimation, slideOutAnimation } from "@twly/common";

export const routeAnimation = trigger("routeAnimations", [
	transition("VenueListPage => VenueDetailPage", slideInAnimation),
	transition("VenueDetailPage => VenueListPage", slideOutAnimation),

	transition("ActivityListPage => ActivityDetailPage", slideInAnimation),
	transition("ActivityDetailPage => ActivityListPage", slideOutAnimation),

	transition("VenueDetailPage => ActivityDetailPage", slideInAnimation),
	transition("ActivityDetailPage => VenueDetailPage", slideOutAnimation),

	transition("UserListPage => UserDetailPage", slideInAnimation),
	transition("UserDetailPage => UserListPage", slideOutAnimation),

	transition("CompanyListPage => CompanyDetailPage", slideInAnimation),
	transition("CompanyDetailPage => CompanyListPage", slideOutAnimation),

	transition("KeywordThumbnailListPage => KeywordBannerListPage", slideInAnimation),
	transition("KeywordBannerListPage => KeywordThumbnailListPage", slideOutAnimation),

	transition("* => *", fadeAnimation)
]);
